import type { ProductAvailabilities } from '@shared/gql/document-nodes'

import { useMemo } from 'react'
import { useDeliveryDate } from 'utils/hooks/delivery/use-delivery-date'

import { isBadAvailability } from '../utils/is-bad-availability'

export const useProductIdsWithLowAvailability = (
  availabilitiesById: Record<string, ProductAvailabilities[] | null | undefined> | null,
  options?: { availabilityDate?: string },
): string[] => {
  const deliveryDate = useDeliveryDate()
  const availabilityDate = options?.availabilityDate || deliveryDate

  return useMemo(() => {
    /** Availabilitites were not queried  */
    if (!availabilitiesById) return []

    /**
     * If any of the products' availabilities are missing,
     * or the label is not "available", some products have low
     * availability.
     * */
    return Object.entries(availabilitiesById)
      .filter(([, availabilities]) => {
        if (!availabilities) return false
        const thisAvailability = availabilities.find(
          (availability) => availability.date === availabilityDate,
        )
        return thisAvailability ? isBadAvailability(thisAvailability) : true
      })
      .map(([id]) => id)
  }, [availabilitiesById, availabilityDate])
}
