import type { ClientCartItem } from '@shared/gql/document-nodes'
import type { FC, MouseEvent } from 'react'

import { getConfig } from '@shared/config'
import { Button, ButtonType, CheckboxSize, DeleteOutline24 } from '@sok/design-system'
import { EmptyCartWarning } from 'components/Modal/EmptyCartWarning'
import { ReplaceCheckBox } from 'components/Product/ReplaceCheckBox'
import { ReplaceabilityInfo } from 'components/ReplaceabililtyInfo'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { clearCart } from 'services/ClientCart/mutations/clear-cart'
import { useToggleReplaceAll } from 'services/ClientCart/mutations/toggle-replace'
import { ModalService } from 'services/Modal'
import styled from 'styled-components'
import { areAllCartItemsReplaceable } from 'utils/cart/is-replaceable'

interface CartHeaderContainerProps {
  cartItems: ClientCartItem[]
  className?: string
}

const { domain } = getConfig()

const _CartHeaderContainer: FC<CartHeaderContainerProps> = ({ cartItems, className }) => {
  const { t } = useTranslation()
  const toggleReplaceAll = useToggleReplaceAll()

  const isReplaceAllChecked = areAllCartItemsReplaceable(cartItems)

  const handleRemoveAll = (e: MouseEvent) => {
    e.preventDefault()
    openModal()
  }

  const openModal = () =>
    ModalService.open<void>(<EmptyCartWarning />, {
      onSubmit: () => {
        clearCart()
      },
    })

  const { isSIdEnabled } = domain

  return (
    <header className={className} data-test-id="cart-header">
      {isSIdEnabled && (
        <>
          <ReplaceCheckBox
            size={CheckboxSize.LARGE}
            data-test-id="toggle-replace"
            onChange={toggleReplaceAll}
            checked={isReplaceAllChecked}
          >
            {t('All can be replaced')}
          </ReplaceCheckBox>

          <ReplaceabilityInfo />
          <Spacer />
        </>
      )}

      <Button
        style={{ padding: 0 }}
        type={ButtonType.TEXT}
        data-test-id="remove-all-icon"
        onClick={handleRemoveAll}
        icon={<DeleteOutline24 />}
        text={t('Clear')}
      />
    </header>
  )
}

_CartHeaderContainer.displayName = 'CartHeaderContainer'

const Spacer = styled.div({
  flex: '1',
})
export const CartHeaderContainer = styled(memo(_CartHeaderContainer))(
  {
    display: 'flex',
    width: '100%',
  },
  ({ theme }) => ({
    padding: theme.spacings.medium,

    span: {
      ...theme.variants.body2.regular,
    },
  }),
)
