import type { FC } from 'react'

import { NextLink } from 'components/NextLink'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { trackCustomEvent } from 'utils/tracking/custom-events/custom-events'
import { EventAction, EventCategory } from 'utils/tracking/interfaces/data-layer-events'

const _CartIsEmpty: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const trackAction = (): void => {
    trackCustomEvent({
      category: EventCategory.ECOMMERCE,
      action: EventAction.CLICK,
      label: 'empty-cart-add-first-item',
    })
  }

  return (
    <div className={className} data-test-id="empty-cart-container">
      <NextLink href="/tuotteet">
        <StyledEmptyCart
          alt={t('Shopping cart is empty')}
          onClick={() => {
            trackAction()
          }}
          src={theme.logos.shoppingCartEmpty}
        />
        <StyledEmptyCartText
          onClick={() => {
            trackAction()
          }}
        >
          {t('Add first product')}
        </StyledEmptyCartText>
      </NextLink>
    </div>
  )
}

export const CartIsEmpty = styled(_CartIsEmpty)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 100%;

  a {
    color: inherit;
    cursor: pointer;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    top: 35%;
  }
`

// 44px is sum of the static height text row below and bottom margin
const StyledEmptyCart = styled.img`
  width: auto;
  height: calc(100% - 44px);
  max-height: 240px;
  margin-bottom: 16px;
`

const StyledEmptyCartText = styled.div(({ theme }) => theme.variants.body2.regular, {
  cursor: 'pointer',
})
