import type { FC } from 'react'

import { InfoOutline16, Popover, PopoverTrigger } from '@sok/design-system'
import { NextLink } from 'components/NextLink'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const ReplaceabilityInfo: FC = () => (
  <Popover
    getPopupContainer={(container) => container}
    trigger={PopoverTrigger.HOVER}
    content={<ReplaceabilityPopover />}
  >
    <ReplaceabilityInfoIconWrapper data-test-id="replaceability-information-icon">
      <InfoOutline16 />
    </ReplaceabilityInfoIconWrapper>
  </Popover>
)

const ReplaceabilityInfoIconWrapper = styled.div(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacings.xSmall,
  paddingRight: theme.spacings.xSmall,
  svg: {
    color: theme.colors.black,
  },

  ['&:hover']: {
    svg: {
      color: theme.colors.primary,
    },
  },
}))

const _ReplaceabilityPopover: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()
  return (
    <div className={className} data-test-id="replaceability-information-popover">
      <h2>{t('Replaceability popover title')}</h2>
      <p>{t('Replaceability popover text')}</p>
      <p>
        <NextLink href="/tuki/tuotteet-ja-korvaaminen#Mill%C3%A4%20perusteella%20tuotteita%20korvataan">
          {t('Replaceability popover link')}
        </NextLink>
      </p>
    </div>
  )
}

const ReplaceabilityPopover = styled(_ReplaceabilityPopover)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacings.medium,

  h2: {
    fontWeight: theme.fontWeights.regular,
  },

  ['p']: {
    marginTop: theme.spacings.medium,
  },

  // prevent link multiline
  a: {
    whiteSpace: 'nowrap',
  },
}))
