import type { ApolloClient } from '@apollo/client'
import type {
  GetProductInfoByEansQuery,
  GetProductInfoByEansQueryVariables,
  Product,
} from '@shared/gql/document-nodes'

import { GetProductInfoByEansDocument } from '@shared/gql/document-nodes'
import { pendingClientCartItemsVar } from 'services/ClientCart/cache'
import { mapUserOrderCartItemsToClientCartItems } from 'utils/mappers/user-order-cart-to-client-cart-mapper'
import { getFilteredUserOrderCartProducts } from 'utils/order/get-filtered-cart-products'
import { getOrderWithCartItems } from 'utils/order/get-order-with-cart-items'

export const setPendingClientCartItems = async (
  apolloClient: ApolloClient<unknown>,
  orderId: string,
  alcoholAllowed: boolean,
): Promise<void> => {
  const orderData = await getOrderWithCartItems(apolloClient, orderId)

  if (!orderData.order?.cartItems?.length) {
    return
  }

  const storeId = orderData.order.storeId
  const filteredCartItems = getFilteredUserOrderCartProducts(orderData.order?.cartItems || [])
  const productData = await apolloClient.query<
    GetProductInfoByEansQuery,
    GetProductInfoByEansQueryVariables
  >({
    query: GetProductInfoByEansDocument,
    variables: {
      availabilityDate: null,
      eans: filteredCartItems.map((c) => c.ean || ''),
      fallbackToGlobal: null,
      id: storeId,
      includeAgeLimitedByAlcohol: alcoholAllowed,
      includeAvailabilities: false /** cart will query these separately */,
    },
  })

  const productListCartItems = (productData.data.store?.products?.items as Product[]) || []
  const mappedCartItems = mapUserOrderCartItemsToClientCartItems(
    filteredCartItems,
    productListCartItems,
  )
  pendingClientCartItemsVar(mappedCartItems)
}
