import type { Maybe } from '@shared/gql/document-nodes'
import type { FC } from 'react'

import { DeliveryMethod, HomeDeliveryType } from '@shared/gql/document-nodes'
import {
  FastTrackOutline24,
  HomeDeliveryOutline24,
  QuickPickupOutline24,
  StoreOutline24,
} from '@sok/design-system'
import { RobotIcon } from 'components/icons'

type DeliveryMethodProps = {
  deliveryMethod: DeliveryMethod
  isFastTrack: Maybe<boolean>
  homeDeliveryType: Maybe<HomeDeliveryType>
}

export const WrappedDeliveryMethodIcon: FC<DeliveryMethodProps> = (props) => {
  const { deliveryMethod, isFastTrack = false, homeDeliveryType = HomeDeliveryType.Normal } = props
  const wrapperClassName = [
    'icon',
    isFastTrack ? 'fast-track' : undefined,
    `${deliveryMethod.toLowerCase()}`,
    homeDeliveryType?.toLowerCase(),
  ]
    .filter(Boolean)
    .join('-')
  return (
    <div className={wrapperClassName}>
      <DeliveryMethodIcon {...props} />
    </div>
  )
}

export const DeliveryMethodIcon: FC<DeliveryMethodProps> = ({
  deliveryMethod,
  isFastTrack = false,
  homeDeliveryType = HomeDeliveryType.Normal,
}) => {
  if (deliveryMethod === DeliveryMethod.Pickup) {
    if (isFastTrack) {
      return <QuickPickupOutline24 />
    }
    return <StoreOutline24 />
  } else {
    if (isFastTrack) {
      if (homeDeliveryType === HomeDeliveryType.Robot) {
        return <RobotIcon />
      }
      return <FastTrackOutline24 />
    }
    return <HomeDeliveryOutline24 />
  }
}
