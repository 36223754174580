export const Shoppinglist = (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="2.5" width="13" height="13" stroke="#484848" />
    <rect x="3.5" width="1" height="4" fill="#484848" />
    <rect x="6.5" width="1" height="4" fill="#484848" />
    <rect x="9.5" width="1" height="4" fill="#484848" />
    <rect x="12.5" width="1" height="4" fill="#484848" />
    <rect x="7.5" y="6" width="6" height="1" fill="#484848" />
    <rect x="7.5" y="9" width="6" height="1" fill="#484848" />
    <rect x="7.5" y="12" width="6" height="1" fill="#484848" />
    <path d="M3.5 5.66667L4.7 7L6.5 5" stroke="#484848" />
    <path d="M3.5 8.66667L4.7 10L6.5 8" stroke="#484848" />
    <path d="M3.5 11.6667L4.7 13L6.5 11" stroke="#484848" />
  </svg>
)
