import type { FC } from 'react'

import { useReactiveVar } from '@apollo/client'
import { Button, Icon } from '@s-group/design-system-components'
import { ProductType } from '@shared/gql/document-nodes'
import { useTranslation } from 'react-i18next'
import { shoppingCartVar } from 'services/ClientCart/cache'
import styled from 'styled-components'
import { useStoreId } from 'utils/hooks/store/use-store-id'

import { Shoppinglist } from './icons/Shoppinglist'
import { useCreateListFromCart } from './support/use-create-list-from-cart'

const _CreateListFromCart: FC<{
  className?: string
  dataTestId?: string
}> = ({ className, dataTestId = 'create-new-list-from-cart' }) => {
  const { t } = useTranslation()
  const { storeId } = useStoreId()
  const createList = useCreateListFromCart(storeId)
  const cart = useReactiveVar(shoppingCartVar)

  const handleCreate = () => {
    createList(cart.filter((item) => item.productType === ProductType.Product))
  }

  return (
    <div className={className}>
      <Button
        data-test-id={dataTestId}
        icon={<Icon svg={Shoppinglist} />}
        iconPos="before"
        onClick={handleCreate}
        rounding="small"
        sizing="small"
        variant="tonal"
        color="grey"
      >
        {t('Create new list from cart')}
      </Button>
    </div>
  )
}
export const CreateListFromCart = styled(_CreateListFromCart)(({ theme }) => ({
  display: 'flex',
  button: {
    flex: 1,
    minHeight: '52px', // send order button height
    margin: theme.spacings.small,
  },
}))
