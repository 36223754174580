import type { FC } from 'react'
import type {
  Notification,
  NotificationAreaMessage,
  NotificationGroup,
} from 'services/Notifications'

import { useReactiveVar } from '@apollo/client'
import { Alert, minWidthFromTheme } from '@sok/design-system'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { Notifications, notificationsVar } from 'services/Notifications'
import styled from 'styled-components'
import { useIsClientSide } from 'utils/hooks/use-is-client-side'

const filterByGroup =
  (filteredGroup: NotificationGroup) =>
  ({ group }: Notification) =>
    group === filteredGroup

const filterAreaMessages = (x: Notification): x is NotificationAreaMessage =>
  x.__kind === 'NotificationAreaMessage'

type NotificationAreaProps = {
  className?: string
  group: NotificationGroup
}

export const _NotificationArea: FC<NotificationAreaProps> = ({ group, className }) => {
  const isClient = useIsClientSide()
  const { dismissed, notifications } = useReactiveVar(notificationsVar)
  const { t } = useTranslation()
  const router = useRouter()
  const visibleNotifications = notifications
    .filter(({ id }) => !dismissed.includes(id))
    .filter(filterByGroup(group))
    .filter(filterAreaMessages)
  return isClient ? (
    <div className={className}>
      {visibleNotifications.map((notification) => (
        <Alert
          key={notification.id}
          data-test-id={notification.id}
          message={notification.message}
          type={notification.type}
          closable
          actionButton={!!notification.button}
          actionHandler={notification.button ? notification.button.onClick(router) : undefined}
          actionText={notification.button?.label && t(notification.button?.label)}
          onClose={() => {
            if (notification.isDismissable) {
              Notifications.dismiss(notification.id)
            } else {
              Notifications.close(notification.id)
            }
          }}
          customizeIcon={notification.customIcon}
        />
      ))}
    </div>
  ) : null
}

export const NotificationArea = styled(_NotificationArea)(({ theme }) => ({
  '.sok-alert': {
    marginBottom: theme.spacings.small,

    '> div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: minWidthFromTheme(theme).largeDesktop,
    },

    a: {
      fontWeight: theme.fontWeights.bold,
    },
  },
}))
