import { useApolloClient, useReactiveVar } from '@apollo/client'

import { DaoService } from '../../LocalStorage'
import { shoppingCartVar } from '../cache'

export const useToggleReplace = () => {
  const apolloClient = useApolloClient()
  const cart = useReactiveVar(shoppingCartVar)

  return async (productId: string): Promise<void> => {
    const newCart = cart.map((x) =>
      x.id === productId
        ? {
            ...x,
            replace: !x.replace,
          }
        : x,
    )

    await DaoService.CartDAO.saveCartItems(newCart, apolloClient)
    shoppingCartVar(newCart)
  }
}

export const useToggleReplaceAll = () => {
  const apolloClient = useApolloClient()
  const cart = useReactiveVar(shoppingCartVar)

  return async (): Promise<void> => {
    const isAllReplaceable = cart.every((x) => x.replace)

    const newCart = cart.map((x) => ({
      ...x,
      replace: !isAllReplaceable,
    }))

    await DaoService.CartDAO.saveCartItems(newCart, apolloClient)
    shoppingCartVar(newCart)
  }
}
