import type { Order, RemoteGetRecentOrdersQuery } from '@shared/gql/document-nodes'
import type { ThemedObjectStyleFunction } from 'domain/styled'
import type { FC } from 'react'

import { useApolloClient } from '@apollo/client'
import { EuroCents } from '@shared/domain/euro-cents'
import { GetOrderByIdDocument } from '@shared/gql/document-nodes'
import { Button, ButtonType } from '@sok/design-system'
import { SkeletonLoader } from 'components/SkeletonLoader'
import { toFormattedDate } from 'domain/date-time'
import { isModifiableOrder } from 'domain/order'
import Link from 'next/link'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { openDeliveryModal } from 'services/Delivery/cache'
import { handleRequestErrors } from 'services/error-handler'
import { useLinkVerificationToken } from 'services/LinkVerificationToken/use-link-verification-token'
import { useGetRecentOrders } from 'services/Order/use-get-recent-orders'
import styled from 'styled-components'
import { setPendingClientCartItems } from 'utils/cart/set-pending-client-cart-items'
import { getDeliveryMethodString } from 'utils/delivery/get-delivery-method-string'
import { useAlcoholAllowedForDeliveryArea } from 'utils/hooks/delivery/use-alcohol-allowed'
import { useStoreId } from 'utils/hooks/store/use-store-id'
import { getOrderWithCartItems } from 'utils/order/get-order-with-cart-items'
import { useReplaceOrder } from 'utils/order/use-edit-order'
import { useTrackingContext } from 'utils/tracking/components/TrackingContextProvider'
import { GA4_addToCart } from 'utils/tracking/track-add-to-cart'
import { WrappedDeliveryMethodIcon } from 'views/order-history/active-orders/DeliveryMethodIcon'
import { useGetHistoryOrderItemsAndMergeToCart } from 'views/order-history/past-orders/use-get-history-order-items-and-set-to-cart'

import { CartIsEmpty } from '../CartIsEmpty'
import { trackCartOrderHistory } from './tracking'

export const CartOrderHistory: FC<{ className?: string }> = () => {
  const orderHistory = useGetRecentOrders()
  const { selectedStoreId } = useStoreId()
  const apolloClient = useApolloClient()
  const [updating, setUpdating] = useState(false)
  const trackingContext = useTrackingContext()

  const alcoholSellingAllowed = useAlcoholAllowedForDeliveryArea()
  const mergeOrderCartItemsToCart = useGetHistoryOrderItemsAndMergeToCart()
  const handleAdd = useCallback(
    async (orderId: string) => {
      if (!selectedStoreId) {
        openDeliveryModal()
        setPendingClientCartItems(apolloClient, orderId, !!alcoholSellingAllowed)
        return
      }

      setUpdating(true)
      try {
        const { order } = await getOrderWithCartItems(apolloClient, orderId)
        if (!order) return

        GA4_addToCart({
          ...trackingContext,
          listId: order.orderNumber,
          products: [...order.cartItems],
        })

        await mergeOrderCartItemsToCart(order)
      } catch (error) {
        handleRequestErrors([error])
      }
      setUpdating(false)
    },
    [
      selectedStoreId,
      apolloClient,
      alcoholSellingAllowed,
      trackingContext,
      mergeOrderCartItemsToCart,
    ],
  )
  const linkVerificationToken = useLinkVerificationToken()
  const enableOrderEdit = useReplaceOrder('/yhteenveto')
  const handleEdit = useCallback(
    async (orderId: string) => {
      setUpdating(true)

      try {
        const orderQuery = await apolloClient.query({
          query: GetOrderByIdDocument,
          variables: {
            id: orderId,
            linkVerificationToken,
          },
          fetchPolicy: 'network-only',
        })

        await enableOrderEdit(orderQuery.data.order as Order)
      } catch (error) {
        handleRequestErrors([error])
        setUpdating(false)
      }
    },
    [apolloClient, enableOrderEdit, linkVerificationToken],
  )

  switch (orderHistory.type) {
    case 'LOADING': {
      return <Loader />
    }

    case 'SUCCESS': {
      if (!orderHistory.data?.length) {
        return <CartIsEmpty />
      }

      return (
        <Success
          handleAdd={handleAdd}
          handleEdit={handleEdit}
          orders={orderHistory.data || []}
          disabled={updating}
        />
      )
    }

    case 'NOT_ASKED':
    case 'FAILURE': {
      return <CartIsEmpty />
    }
  }
}

const cartOrderHistoryStyle: ThemedObjectStyleFunction = ({ theme }) => ({
  padding: theme.spacings.medium,
  textAlign: 'left',
  flex: 1,
  ['.footer']: {
    padding: theme.spacings.medium,
    textAlign: 'center',
  },

  h3: {
    ...theme.sizes.s,
    textAlign: 'center',
    color: theme.colors.grey800,
    fontWeight: theme.fontWeights.regular,
    padding: theme.spacings.medium,
    margin: 0,
    borderBottom: `1px solid ${theme.colors.grey300}`,
  },
  h4: {
    fontWeight: theme.fontWeights.regular,
  },

  ['.item']: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${theme.spacings.xSmall} 0 0 0`,
    borderBottom: `1px solid ${theme.colors.grey200}`,

    ['.item__icon']: {
      marginRight: theme.spacings.medium,
    },

    ['.item__container']: {
      flex: 1,
    },

    ['.item__additional']: {
      marginTop: theme.spacings.xxSmall,
      ...theme.sizes.xxs,
    },
    button: {
      padding: 0,
      margin: 0,
    },
  },

  ['&._disabled .item ']: {
    opacity: 0.3,
  },

  ['a, a:visited']: {
    color: theme.colors.primary,
    fontWeight: theme.fontWeights.medium,
  },
  // Icons are not horizontally aligned when placed on top of each other
  // Offset them a bit when shown inside this list
  ['.icon-fast-track-pickup']: {
    transform: 'translateX(-13px)',
  },
  ['.icon-fast-track-home_delivery-robot']: {
    transform: 'translateX(-2px)',
  },
  ['.icon-fast-track-home_delivery-normal']: {
    transform: 'translateX(-5px)',
  },
})

const Loader = styled(({ className }) => (
  <div className={className} data-test-id="recent-orders__loader">
    <h3 className="title">
      <SkeletonLoader width={200} />
    </h3>
    {Array(5)
      .fill(false)
      .map((_, i) => (
        <div className="item" key={i}>
          <div className="item__icon">
            <SkeletonLoader width={32} height={32} />
          </div>
          <div className="item__container">
            <h4 className="item__details">
              <SkeletonLoader width={200} />
            </h4>
            <h4 className="item__additional">
              <SkeletonLoader width={200} />
            </h4>

            <div className="item__additional">
              <SkeletonLoader width={150} />
            </div>
          </div>
        </div>
      ))}
  </div>
))(cartOrderHistoryStyle, ({ theme }) => ({
  ['.item']: {
    paddingBottom: theme.spacings.xSmall,
    marginBottom: theme.spacings.xSmall,
  },
}))

const Success = styled<
  FC<{
    className?: string
    disabled: boolean
    handleAdd: (orderId: string) => void
    handleEdit: (orderId: string) => void
    orders: RemoteGetRecentOrdersQuery['userOrders']
  }>
>((props) => {
  const { t } = useTranslation()

  const [openOrders, closedOrders] = useMemo(
    // there are only very limited set of orders available here (less than 10) so
    // no need to perform any magic filtering optimizations
    () => [
      props.orders.filter(isModifiableOrder),
      props.orders.filter((x) => !isModifiableOrder(x)),
    ],
    [props.orders],
  )

  return (
    <div
      className={[props.className, props.disabled ? '__disabled' : ''].join(' ')}
      data-test-id="recent-orders"
    >
      {openOrders.length ? (
        <>
          <h3 className="title" data-test-id="recent-orders__title">
            {t('CartOrderHistory_OpenOrders')}
          </h3>
          <OrderHistoryItemList
            orders={openOrders}
            disabled={props.disabled}
            handleAdd={props.handleAdd}
            handleEdit={props.handleEdit}
          />
        </>
      ) : null}
      {closedOrders.length ? (
        <>
          <h3 className="title" data-test-id="recent-orders__title">
            {t('CartOrderHistory_ClosedOrders')}
          </h3>

          <OrderHistoryItemList
            orders={closedOrders}
            disabled={props.disabled}
            handleAdd={props.handleAdd}
            handleEdit={props.handleEdit}
          />
        </>
      ) : null}
      <div className="footer" data-test-id="recent-orders__footer">
        <Link passHref href="/tilaus/historia">
          {/* eslint-disable-next-line */}
          <a tabIndex={0} onClick={trackCartOrderHistory.showOrderHistory}>
            {t('Navigate to order history')}
          </a>
        </Link>
      </div>
    </div>
  )
})(cartOrderHistoryStyle)

const OrderHistoryItemList: FC<{
  orders: RemoteGetRecentOrdersQuery['userOrders']
  disabled: boolean
  handleAdd: (orderId: string) => void
  handleEdit: (orderId: string) => void
}> = (props) => {
  const { t } = useTranslation()
  const handleAdd = (orderId: string, index: number) => () => {
    props.handleAdd(orderId)
    trackCartOrderHistory.clickAddToCart(index)
  }
  const handleEdit = (orderId: string) => () => {
    props.handleEdit(orderId)
    trackCartOrderHistory.startEditOrder()
  }

  return (
    <>
      {props.orders.map((order, index) => (
        <div
          className="item"
          key={order.id}
          data-test-id="recent-orders__list-item"
          data-order-id={order.id}
        >
          <div className="item__icon">
            <WrappedDeliveryMethodIcon
              deliveryMethod={order.deliveryMethod}
              isFastTrack={order.isFastTrack}
              homeDeliveryType={order.homeDeliveryType}
            />
          </div>
          <div className="item__container">
            <h4 className="item__details">
              <span className="item__store-name">{order.storeName}</span>
              <span className="item__spacer">{' - '}</span>
              <span className="item__price">{EuroCents.fromEuros(order.totalCost).format()}</span>
            </h4>
            <h4 className="item__additional">
              <span className="item__state">{t(`Past order ${order.orderStatus}`)}, </span>
              <span className="item__delivery">
                {`${t(
                  getDeliveryMethodString(
                    order.deliveryMethod,
                    order.isFastTrack,
                    order.homeDeliveryType,
                  ),
                )} ${toFormattedDate(order.deliveryDate, {
                  year: false,
                  weekday: false,
                })} | ${order.deliveryTime}`}
              </span>
            </h4>
            {order.isModifiable ? (
              <Button
                type={ButtonType.LINK}
                onClick={handleEdit(order.id)}
                text={t('Modify order')}
                disabled={props.disabled}
              />
            ) : (
              <Button
                type={ButtonType.LINK}
                onClick={handleAdd(order.id, index)}
                text={t('Add products to cart')}
                disabled={props.disabled}
              />
            )}
          </div>
        </div>
      ))}
    </>
  )
}
