import type { ClientCartItem } from '@shared/gql/document-nodes'

import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { handleRequestErrors } from 'services/error-handler'
import { ModalService } from 'services/Modal'
import { addSnackbar } from 'services/Snackbar/Snackbars'

import { EditListModal } from '../EditListModal'
import { paths } from '../paths'
import { mapCartItemsToList, useCreateListWithItemsQuery } from '../queries/create-list-with-items'
import { ShoppinglistTracking } from '../tracking'

export const useCreateListFromCart = (storeId: string) => {
  const createList = useCreateListWithItemsQuery()
  const { t } = useTranslation()
  const router = useRouter()

  return (cart: ClientCartItem[]) => {
    ModalService.open<string>(<EditListModal title={t('Create new shopping list')} />, {
      onSubmit: async (name: string) => {
        try {
          const response = await createList(name, storeId, mapCartItemsToList(cart))
          ShoppinglistTracking.CreateShoppingListFromCart(
            name,
            cart.map((x) => ({
              id: x.id,
              ean: x.ean,
              name: x.name,
              quantity: x.itemCount,
            })),
          )
          addSnackbar({
            text: t('Created new list from cart'),
            actionText: t('Goto new list from cart'),
            onAction: () => router.push(paths.list(response.data?.createShoppingList?.id || '')),
          })
        } catch (e) {
          handleRequestErrors([e])
        }
      },
    })
  }
}
