import type { GetUserOrderByIdQuery, ProductList } from '@shared/gql/document-nodes'

import { AlcoholSellingStatus } from '@shared/gql/document-nodes'
import { mergeCartItems } from 'domain/map-products-to-cart-items'
import { shoppingCartVar } from 'services/ClientCart/cache'
import { useSetCartItems } from 'services/ClientCart/mutations/set-cart-items'
import { openTimeAlcoholConflictModal } from 'services/Delivery/cache'
import { useSelectedDeliverySlot } from 'services/DeliverySlot/hooks'
import { useStoreId } from 'utils/hooks/store/use-store-id'
import { mapUserOrderCartItemsToClientCartItems } from 'utils/mappers/user-order-cart-to-client-cart-mapper'
import { getFilteredUserOrderCartProducts } from 'utils/order/get-filtered-cart-products'
import { useGetProductList } from 'views/shoppinglists/queries/use-add-list-to-cart'

type UseGetHistoryOrderItemsAndSetToCart = () => (order: NonNullableUserOrder) => Promise<void>
export type NonNullableUserOrder = NonNullable<GetUserOrderByIdQuery['userOrder']>

export const useGetHistoryOrderItemsAndMergeToCart: UseGetHistoryOrderItemsAndSetToCart = () => {
  const getProductList = useGetProductList()
  const clientCartItems = shoppingCartVar()
  const setCartItems = useSetCartItems()
  const selectedSlot = useSelectedDeliverySlot()
  const { selectedStoreId } = useStoreId()

  return async (order: NonNullableUserOrder) => {
    const cartItems = getFilteredUserOrderCartProducts(order.cartItems || [])
    const eans = cartItems.map((c) => c.ean || '')
    const { items } = (await getProductList(eans, selectedStoreId)) as ProductList
    const convertedClientCartItems = mapUserOrderCartItemsToClientCartItems(cartItems, items || [])
    const compiledItems = mergeCartItems(convertedClientCartItems, clientCartItems)

    if (cartItems.length) {
      await setCartItems(compiledItems)
    }
    const aProductIsAgeLimitedByAlcohol = !!items?.find((item) => item?.isAgeLimitedByAlcohol)
    const alcoholSellingNotAllowedForSlot =
      selectedSlot.type === 'SUCCESS' &&
      selectedSlot.data.alcoholSellingAllowed === AlcoholSellingStatus.NotAllowed

    if (alcoholSellingNotAllowedForSlot && aProductIsAgeLimitedByAlcohol) {
      openTimeAlcoholConflictModal()
    }
  }
}
