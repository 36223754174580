import { trackCustomEvent } from './custom-events/custom-events'
import { EventAction, EventCategory } from './interfaces/data-layer-events'

type CartTarget = 'shopping-cart' | 'side-cart'

export const trackCartReplacementToggle = (target: CartTarget, toggleStatus: boolean) => {
  trackCustomEvent({
    category: EventCategory.SHOPPING_CART_ELEMENTS,
    action: EventAction.CLICK,
    label: `${target}_may-replace-${toggleStatus ? 'true' : 'false'}`,
  })
}

export const trackCartCommentOpen = (target: CartTarget) => {
  trackCustomEvent({
    category: EventCategory.SHOPPING_CART_ELEMENTS,
    action: EventAction.CLICK,
    label: `${target}_open-comment`,
  })
}

export const trackCartCommentSave = (target: CartTarget) => {
  trackCustomEvent({
    category: EventCategory.SHOPPING_CART_ELEMENTS,
    action: EventAction.CLICK,
    label: `${target}_save-comment`,
  })
}

export const trackCartRemoveItem = (target: CartTarget) => {
  trackCustomEvent({
    category: EventCategory.SHOPPING_CART_ELEMENTS,
    action: EventAction.CLICK,
    label: `${target}_remove-item`,
  })
}
