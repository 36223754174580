import type { ClientCartItem } from '@shared/gql/document-nodes'
import type { FC } from 'react'

import { DeliveryMethod } from '@shared/gql/document-nodes'
import { Alert, AlertType } from '@sok/design-system'
import { LowAvailabilityWarning } from 'components/ShoppingCart/mobileCart/LowAvailabilityWarning'
import { useTranslation } from 'react-i18next'
import { useRemoveCartItems } from 'services/ClientCart/mutations/use-remove-cart-items'
import { useProductAvailabilities } from 'services/Product/hooks/use-product-availabilities'
import { useProductIdsWithLowAvailability } from 'services/Product/hooks/use-product-ids-with-low-availability'
import styled from 'styled-components'
import { getMissingItems } from 'utils/cart/filter-missing-items'
import { useGetCartAlcoholItems } from 'utils/hooks/cart'
import { useGetInitialDeliverySelections } from 'views/delivery-modal/method-and-store/use-get-initial-delivery-selections'

interface CartAlertsContainerProps {
  cartItems: ClientCartItem[]
}

export const CartAlertsContainer: FC<CartAlertsContainerProps> = ({ cartItems }) => {
  const { t } = useTranslation()
  const alcoholItems = useGetCartAlcoholItems()
  const availabilitiesById = useProductAvailabilities(cartItems)
  const lowAvailabilities = useProductIdsWithLowAvailability(availabilitiesById)
  const { initialDeliveryMethod } = useGetInitialDeliverySelections()
  const removeCartItems = useRemoveCartItems()
  const isHomeDelivery = initialDeliveryMethod === DeliveryMethod.HomeDelivery
  const missingItems = getMissingItems(cartItems)

  const handleRemovalAll = () => removeCartItems([...alcoholItems, ...missingItems])
  const handleRemoveMissing = () => removeCartItems(missingItems)

  return (
    <>
      {lowAvailabilities.length > 0 && <LowAvailabilityWarning />}

      {!isHomeDelivery && missingItems.length > 0 && (
        <StyledAlert
          message={t('Missing items')}
          type={AlertType.ERROR}
          actionButton
          data-test-id="remove-missing-items"
          actionText={t('Remove missing')}
          actionHandler={handleRemoveMissing}
        />
      )}

      {isHomeDelivery && (alcoholItems.length > 0 || missingItems.length > 0) && (
        <StyledAlert
          message={t('Items not available for delivery')}
          type={AlertType.ERROR}
          actionButton
          actionText={t('Remove missing')}
          actionHandler={handleRemovalAll}
          data-test-id="remove-missing-items"
        />
      )}
    </>
  )
}

const StyledAlert = styled(Alert)({
  width: '100%',
})
