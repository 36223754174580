import type { ProductAvailabilities } from '@shared/gql/document-nodes'

import { useQuery } from '@apollo/client'
import { getConfig } from '@shared/config'
import { RemoteProductAvailabilitiesDocument } from '@shared/gql/document-nodes'
import { useMemo } from 'react'
import { useDeliveryDate } from 'utils/hooks/delivery/use-delivery-date'
import { useStoreId } from 'utils/hooks/store/use-store-id'
import { isNonNullable } from 'utils/nullable'

const { featureFlags } = getConfig()

export const useProductAvailabilities = (
  products: { id: string }[],
  options?: { availabilityDate?: string; storeId?: string },
): Record<string, ProductAvailabilities[] | null> => {
  const deliveryDate = useDeliveryDate()
  const availabilityDate = options?.availabilityDate || deliveryDate

  const productIds = useMemo(() => products.map((p) => p.id), [products])

  const { selectedStoreId } = useStoreId()
  const storeId = options?.storeId || selectedStoreId

  const queryResult = useQuery(RemoteProductAvailabilitiesDocument, {
    fetchPolicy: 'cache-first',
    skip:
      !featureFlags.productAvailabilities || !availabilityDate || !productIds.length || !storeId,
    ssr: false,
    variables: { availabilityDate, productIds, storeId },
  })

  return useMemo(() => {
    return (
      (queryResult.data ?? queryResult.previousData)?.store?.products?.items
        ?.filter(isNonNullable)
        ?.reduce((acc, product) => {
          return { ...acc, [product.id]: product?.availabilities }
        }, {}) || {}
    )
  }, [queryResult.data, queryResult.previousData])
}
