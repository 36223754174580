import type { FC, ReactNode } from 'react'

import { useQuery } from '@apollo/client'
import { Button } from '@s-group/design-system-components'
import { GetAllClientCartItemsDocument } from '@shared/gql/document-nodes'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useGetCartTotal } from 'utils/hooks/cart'
import { useIsClientSide } from 'utils/hooks/use-is-client-side'
import { useGetOrderEditState } from 'utils/order/use-get-order-edit-state'

interface Props {
  className?: string
  href?: string
  'data-test-id'?: string
  showPrice?: boolean
  children?: ReactNode
}

const _CheckoutButton: FC<Props> = ({
  className,
  children,
  'data-test-id': dataTestId,
  href = '/toimitus',
  showPrice,
}) => {
  const { t } = useTranslation()
  const { data: cartItemsData, loading, error } = useQuery(GetAllClientCartItemsDocument)
  const cartTotal = useGetCartTotal()
  const orderEditState = useGetOrderEditState()
  const isClientSide = useIsClientSide()

  if (loading || error || !cartItemsData) {
    return null
  }

  const isDisabled = !isClientSide || cartItemsData.cartItems.length === 0

  const title = orderEditState.isOrderEditActive ? t('To order info') : children

  return (
    <div className={className}>
      <Link href={isDisabled ? '' : href} passHref>
        <Button
          primary
          aria-disabled={isDisabled}
          disabled={isDisabled}
          data-test-id={dataTestId}
          sizing="medium"
          rounding="small"
        >
          {showPrice && isClientSide ? (
            <>
              <span>{title}</span>
              <span>{cartTotal.format()}</span>
            </>
          ) : (
            title
          )}
        </Button>
      </Link>
    </div>
  )
}

export const CheckoutButton = styled(_CheckoutButton)({
  'a[aria-disabled="true"]': {
    cursor: 'not-allowed',
    pointerEvents: 'none',
  },

  [Button]: {
    cursor: 'pointer',
    justifyContent: 'space-evenly',
    width: '100%',
  },
})
