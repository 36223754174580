import type { ClientCartItem } from '@shared/gql/document-nodes'
import type { CreateProductResolvedShoppingListItemInput } from '@shared/gql/sostoslista-document-nodes'

import { useMutation } from '@apollo/client'
import {
  So_RemoteCreateUserListWithProductsDocument,
  So_RemoteGetUserListsDocument,
} from '@shared/gql/sostoslista-document-nodes'

import { shoppingListsQueryContext } from '../queries/shopping-lists-query-context'

export const useCreateListWithItemsQuery = () => {
  const [createList] = useMutation(So_RemoteCreateUserListWithProductsDocument, {
    ...shoppingListsQueryContext,
    update: (cache, response) => {
      if (response.errors || !response.data) {
        throw new Error('Could not create list')
      }
      const lists = cache.readQuery({
        query: So_RemoteGetUserListsDocument,
      })

      cache.writeQuery({
        query: So_RemoteGetUserListsDocument,
        data: {
          __typename: 'Query',
          shoppingLists: [...(lists?.shoppingLists || []), response.data.createShoppingList],
        },
      })
    },
  })
  return (name: string, storeId: string, items: CreateProductResolvedShoppingListItemInput[]) =>
    createList({
      variables: {
        name,
        storeId,
        items,
      },
    })
}

const cartItemToListItem = (x: ClientCartItem): CreateProductResolvedShoppingListItemInput => ({
  collected: false,
  commentForPicker: x.additionalInfo,
  ean: x.ean,
  isReplaceable: x.replace,
  name: x.name,
  quantity: x.itemCount,
  quantityUnit: x.basicQuantityUnit,
  sokId: x.ean,
})

export const mapCartItemsToList = (
  xs: ClientCartItem[],
): CreateProductResolvedShoppingListItemInput[] => xs.map(cartItemToListItem)
