import { trackCustomEvent } from 'utils/tracking/custom-events/custom-events'
import { EventAction, EventCategory } from 'utils/tracking/interfaces/data-layer-events'

export const trackCartOrderHistory = {
  clickAddToCart: (itemPosition: number) =>
    trackCustomEvent({
      action: EventAction.CLICK,
      category: EventCategory.ECOMMERCE,
      label: `empty-cart-use-previous-order-${itemPosition}`,
    }),
  startEditOrder: () =>
    trackCustomEvent({
      action: EventAction.CLICK,
      category: EventCategory.ECOMMERCE,
      label: `empty-cart-edit-order`,
    }),
  showOrderHistory: () =>
    trackCustomEvent({
      action: EventAction.CLICK,
      category: EventCategory.ECOMMERCE,
      label: `empty-cart-view-order-history`,
    }),
}
