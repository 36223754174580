import type { RemoteGetRecentOrdersQuery } from '@shared/gql/document-nodes'
import type { RemoteData } from 'domain/remote-data'

import { useQuery } from '@apollo/client'
import {
  Domain,
  RemoteGetRecentOrdersDocument,
  UserOrderDataSource,
} from '@shared/gql/document-nodes'
import { remoteData } from 'domain/remote-data'
import { pipe } from 'fp-ts/function'
import { useIsLoggedIn } from 'utils/hooks/account/use-is-logged-in'

export const useGetRecentOrders = (): RemoteData<
  Error,
  RemoteGetRecentOrdersQuery['userOrders'] | null
> => {
  const isAuthenticated = useIsLoggedIn()

  const queryResult = useQuery(RemoteGetRecentOrdersDocument, {
    variables: {
      domain: Domain.SKaupat,
      dataSources: [UserOrderDataSource.SKaupat],
      limit: 5,
    },
    skip: !isAuthenticated,
  })

  return pipe(
    queryResult,
    remoteData.from,
    remoteData.map((data) => data?.userOrders || null),
  )
}
