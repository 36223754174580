const hasEan = <T>(t: T): t is T & { ean: string } => t && typeof t === 'object' && 'ean' in t

const MISSING = '_MISSING_'

/**
 * From product list, return copy without duplicate eans.
 *
 * @example
 * const products = [{ ean: '1234' }, { ean: '1234' }]
 * uniqueByEan(products) // [{ ean: '1234' }]
 */
export const uniqueByEan = <T extends object>(products: T[]): T[] =>
  Object.values<T>(
    /**
     * create a record like `{ [ean]: product }` and returns its
     * values to get a list of unique products.
     */
    products.reduce<Record<string | typeof MISSING, T>>((collection, product) => {
      if (hasEan(product)) {
        /** a product by this ean is already in collection */
        if (product.ean in collection) return collection

        /** add this product to collection */
        collection[product.ean] = product
      } else {
        /**
         * this product has no ean, but there's another already in collection.
         * Not sure if it's necessary to handle cases where a "product" doesn't
         * have an ean, but this is for backwards-compatibility with Ramda's
         * `uniqWith(egProps('ean'), [product])`.
         */
        if (MISSING in collection) return collection

        /** add this product without ean to collection */
        collection[MISSING] = product
      }

      return collection
    }, {}),
  )
