import type { CartItem, ClientCartItem, UserOrderCartItem } from '@shared/gql/document-nodes'
import type { ProductData } from 'components/Product/ProductCard'

import { ProductType } from '@shared/gql/document-nodes'

type ProductDataWithItemCount = ProductData & { itemCount: number }

interface PartitionedData {
  productCartItems: ProductDataWithItemCount[]
  otherItems: ProductDataWithItemCount[]
}

/**
 * Returns a PartitionedData of product Items of type = 'PRODUCT'
 * and other Items of type, such as  'SERVICE_FEE' | 'COUPON' | 'DELIVERY_PRODUCT' | 'DEPOSIT' | 'PACKAGING_PRODUCT'
 */
export const paritionProductItems = (
  cartItems: (ClientCartItem | UserOrderCartItem | CartItem)[],
  storeId: string,
): PartitionedData => {
  const productCartItems = cartItems
    .filter((cartItem) => cartItem.productType === ProductType.Product)
    .map((cartItem) => mapOrderCartItemToProduct(cartItem, storeId))

  const otherItems = cartItems
    .filter((cartItem) => cartItem.productType !== ProductType.Product)
    .map((cartItem) => mapOrderCartItemToProduct(cartItem, storeId))

  return {
    productCartItems,
    otherItems,
  }
}

export const mapOrderCartItemToProduct = (
  x: UserOrderCartItem | ClientCartItem | CartItem,
  storeId: string,
): ProductDataWithItemCount => ({
  ...x,
  itemCount: Number(x.itemCount) ?? 0,
  replace: !!x.replace,
  storeId,
  packagingLabelCodes: null,
  frozen: null,
  isAgeLimitedByAlcohol: null,
  quantityMultiplier: null,
  approxPrice: null,
  comparisonPrice: null,
  comparisonUnit: null,
  isGlobalFallback: null,
  pricing:
    x.__typename === 'ClientCartItem'
      ? {
          __typename: 'ProductPricing',
          campaignPrice: x.campaignPrice,
          campaignPriceValidUntil: x.campaignPriceValidUntil,
          lowest30DayPrice: x.lowest30DayPrice,
          // this is typed in gql as non-nullable
          regularPrice: x.regularPrice || 0,
        }
      : null,
})

export const mapCartItemToProduct = (x: ClientCartItem, storeId: string): ProductData => ({
  ...x,
  storeId,
  itemCount: Number(x.itemCount) ?? 0,
  isGlobalFallback: null,
  pricing: {
    __typename: 'ProductPricing',
    campaignPrice: x.campaignPrice,
    campaignPriceValidUntil: x.campaignPriceValidUntil,
    lowest30DayPrice: x.lowest30DayPrice,
    // this is typed in gql as non-nullable
    regularPrice: x.regularPrice || 0,
  },
})
