import type {
  OstosListaEventAction,
  OstosListaEventProductItem,
} from '@shared/domain/tracking/ostoslista'

import { dataLayer } from 'utils/tracking/tag-manager'

const trackEvent = (
  action: OstosListaEventAction,
  listName?: string,
  products?: OstosListaEventProductItem[],
  context?: string,
) =>
  dataLayer({
    event: 'customEvent',
    customEvent: {
      category: 'shoppinglists',
      listName,
      action,
      context,
      ...(products
        ? {
            ecommerce: {
              products,
            },
          }
        : {}),
    },
    ...(products
      ? {
          ecommerce: {
            products,
          },
        }
      : {}),
  })

const mapShoppingListProductToProductItem = ({
  id,
  quantity,
  isReplaceable,
  product,
}: PartialItem) => ({
  id,
  quantity,
  isReplaceable,
  ...(product || {}),
})
export const mapShoppinglistItemsToGa4 = (items: PartialItem[]) =>
  items.map(mapShoppingListProductToProductItem)

type ListEventSource = 'product_page' | 'search_modal' | 'shopping_list'
type PartialItem = Partial<{
  id?: string | null
  quantity?: number | null
  isReplaceable?: boolean | null
  product?: {
    ean?: string | null
    name?: string | null
    price?: number | null
    hierarchyPath?: { name: string }[] | null
  } | null
}>

export const ShoppinglistTracking = {
  ListsViewError: () => trackEvent('shopping_list_view_lists_error'),
  NotLoggedIn: () => trackEvent('shopping_list_not_logged_in'),
  LoginToViewShoppingLists: () => trackEvent('shopping_list_login_to_view'),
  RegisterToViewShoppingLists: () => trackEvent('shopping_list_register_to_view'),
  ViewShoppingLists: () => trackEvent('shopping_list_view_lists'),
  NoShoppingLists: () => trackEvent('shopping_list_no_lists'),

  RemoveShoppingList: (listName: string) => trackEvent('shopping_list_remove', listName),
  CreateShoppingList: (listName: string) => trackEvent('shopping_list_create', listName),
  CreateShoppingListFromCart: (listName: string, items: PartialItem[]) =>
    trackEvent('shopping_list_create_from_cart', listName, items?.map(mapToTrackingItem)),
  UpdateShoppingList: (listName: string) => trackEvent('shopping_list_update', listName),

  AddItemToList: (source: ListEventSource, listName: string, x: PartialItem) =>
    trackEvent('shopping_list_add_to_list', listName, [mapToTrackingItem(x)], source),
  RemoveItemFromList: (source: ListEventSource, listName: string, x: PartialItem) =>
    trackEvent('shopping_list_delete_item', listName, [mapToTrackingItem(x)], source),
  UndoRemoveItemFromList: (source: ListEventSource, listName: string, x: PartialItem) =>
    trackEvent('shopping_list_undo_delete_item', listName, [mapToTrackingItem(x)], source),

  ModifyItemCount: (listName: string, x: PartialItem) =>
    trackEvent('shopping_list_modify_item_count', listName, [mapToTrackingItem(x)]),
  ModifyItemComment: (listName: string, x: PartialItem) =>
    trackEvent('shopping_list_modify_item_comment', listName, [mapToTrackingItem(x)]),
  ModifyItemReplaceability: (listName: string, x: PartialItem) =>
    trackEvent('shopping_list_modify_item_replaceability', listName, [mapToTrackingItem(x)]),
  ToggleReplaceAll: (listName: string) => trackEvent('shopping_list_toggle_replace_all', listName),
  ViewShoppingListWithNoProducts: () => trackEvent('shopping_list_no_products'),
  ViewShoppingListNotFound: () => trackEvent('shopping_list_list_no_longer_exists'),
}

const mapToTrackingItem = (x: PartialItem) => ({
  ean: x.product?.ean,
  id: x.id,
  quantity: x.quantity,
  name: x.product?.name,
  isReplaceable: x.isReplaceable,
})
