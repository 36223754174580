import type { UserOrderCartItem } from '@shared/gql/document-nodes'

import { ProductType } from '@shared/gql/document-nodes'
import { uniqueByEan } from 'utils/products/unique-by-ean'

export const getFilteredUserOrderCartProducts = (
  cartItems: UserOrderCartItem[],
): UserOrderCartItem[] => {
  const uniqItems = uniqueByEan(cartItems)
  return uniqItems.filter((item) => !!item.ean && item.productType === ProductType.Product)
}
