import type { FC } from 'react'

import { Button } from '@s-group/design-system-components'
import { SDS_SPACE_XXSMALL } from '@s-group/design-system-tokens/web/tokens/space.es6'
import { minWidthFromTheme } from '@sok/design-system'
import { Modal } from 'components/Modal/Modal'
import { useTranslation } from 'react-i18next'
import { useModal } from 'services/Modal'
import styled from 'styled-components'

export const EmptyCartWarning: FC = () => {
  const { t } = useTranslation()
  const modal = useModal()

  return (
    <Modal.Dialogue
      title={t('Empty shoppingcart')}
      dataTestId="modal-empty-cart-warning"
      onClose={modal.onCancel}
      footer={
        <StyledButtons>
          <Button
            data-test-id="btn-empty-cart-confirm"
            onClick={modal.onSubmit}
            rounding="small"
            primary
          >
            {t('Confirm')}
          </Button>
          <Button data-test-id="btn-empty-cart-cancel" onClick={modal.onCancel} rounding="small">
            {t('Cancel')}
          </Button>
        </StyledButtons>
      }
    >
      <div data-test-id="modal-empty-confirmation">{t('Confirm cart empty')}</div>
    </Modal.Dialogue>
  )
}

const StyledButtons = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: SDS_SPACE_XXSMALL,
  [minWidthFromTheme(theme).tablet]: {
    flexDirection: 'row',
  },
}))
