import type { ApolloClient } from '@apollo/client'
import type {
  GetUserOrderByIdQuery,
  GetUserOrderByIdQueryVariables,
} from '@shared/gql/document-nodes'

import { GetUserOrderByIdDocument } from '@shared/gql/document-nodes'

interface OrderWithCartItems {
  order: GetUserOrderByIdQuery['userOrder']
  loading: boolean
  error?: string
}

export const getOrderWithCartItems = async (
  apolloClient: ApolloClient<unknown>,
  orderId: string,
): Promise<OrderWithCartItems> => {
  const { data, error, loading } = await apolloClient.query<
    GetUserOrderByIdQuery,
    GetUserOrderByIdQueryVariables
  >({
    query: GetUserOrderByIdDocument,
    variables: {
      id: orderId,
    },
  })
  return { order: data.userOrder, error: error?.message, loading: loading }
}
