import type { FC } from 'react'
import type { ValidationError } from 'validation/validators'

import { Button } from '@s-group/design-system-components'
import { Form, TextField } from 'components/form'
import { Modal } from 'components/Modal/Modal'
import * as E from 'fp-ts/Either'
import { flow, pipe } from 'fp-ts/function'
import { useTranslation } from 'react-i18next'
import { useModal } from 'services/Modal'
import styled from 'styled-components'
import { setValidationErrorMessage } from 'validation/set-validation-error-message'
import * as V from 'validation/validators'

const _EditListModal: FC<{
  className?: string
  name?: string
  title: string
}> = ({ className, title, name = '' }) => {
  const modal = useModal<string>()
  const { t } = useTranslation()
  const makeList = async (x: { name: string }) => {
    if (E.isRight(listNameV(x))) {
      modal.onSubmit(x.name)
    }
  }

  return (
    <Modal.Modal
      dataTestId="edit-shoppinglist-modal"
      onClose={modal.onCancel}
      title={title}
      footer={
        <ButtonWrapper>
          <Button
            form="shoppinglist-form"
            type="submit"
            data-test-id="form-submit"
            primary
            rounding="small"
          >
            {t('Save')}
          </Button>
        </ButtonWrapper>
      }
    >
      <div className={className}>
        <Form
          id={'shoppinglist-form'}
          model={{
            name,
          }}
          validator={listNameV}
          onSubmit={makeList}
        >
          <TextField
            name={'name'}
            label={t('New shopping list name')}
            data-test-id="shopping-list-name"
          />
        </Form>
      </div>
    </Modal.Modal>
  )
}

const listNameV = (x: { name: string | null }): E.Either<ValidationError[], { name: string }> =>
  V.validate({
    name: pipe(
      V.required(['name'])(x.name),
      E.mapLeft(setValidationErrorMessage('Pakollinen kenttä')),
      E.chain(
        flow(V.plaintext(['name']), E.mapLeft(setValidationErrorMessage('Tarkista kirjoitusasu'))),
      ),
    ),
  })

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})
export const EditListModal = styled(_EditListModal)({
  width: '500px',
  maxWidth: '90vw',
})
