import type { ClientCartItem } from '@shared/gql/document-nodes'

const isAlcohol = (cartItem: ClientCartItem): boolean => cartItem.isAgeLimitedByAlcohol === true

export const filterAlcoholItems = (cartItems: ClientCartItem[]): ClientCartItem[] =>
  cartItems?.filter((ci) => !isAlcohol(ci))

export const sortAlcoholOnTop = (a: ClientCartItem, b: ClientCartItem): number => {
  if (a.isAgeLimitedByAlcohol === b.isAgeLimitedByAlcohol) return 0
  return a.isAgeLimitedByAlcohol ? -1 : 1
}
