import { useApolloClient, useReactiveVar } from '@apollo/client'

import { DaoService } from '../../LocalStorage'
import { shoppingCartVar } from '../cache'

export const useSetAdditionalInfo = () => {
  const apolloClient = useApolloClient()
  const cart = useReactiveVar(shoppingCartVar)

  return async (id: string, additionalInfo: string) => {
    const newCart = cart.map((x) =>
      x.id === id
        ? {
            ...x,
            additionalInfo,
          }
        : x,
    )

    await DaoService.CartDAO.saveCartItems(newCart, apolloClient)
    shoppingCartVar(newCart)
  }
}
